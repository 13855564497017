// Vue
import { ref, Ref, provide, inject, type InjectionKey } from 'vue';

type SnackbarNotificationOptions = {
  message: string;
  caption?: string;
  color?: string;
  timeout?: number;
  html?: boolean;
}

type SnackbarErrorOptions = {
  caption?: string;
  color?: string;
  timeout?: number;
  html?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any; // Error needs to be `any` otherwise TS forces us to cast everywhere we pass an error since errors are technically unknown
}

export type NotificationService = {
  show: Ref<boolean>;
  message: Ref<string>;
  caption: Ref<string>;
  color: Ref<string>;
  timeout: Ref<number>;
  notify: (options: SnackbarNotificationOptions) => void;
  error: (options: SnackbarErrorOptions) => void;
}

// Create a symbol for injection key
export const NotificationSymbol: InjectionKey<NotificationService> = Symbol('NotificationService');

// Create the notification service
export const createNotificationService = (): NotificationService => {
  const show = ref(false);
  const message = ref('');
  const caption = ref('');
  const color = ref('red');
  const timeout = ref(5000);

  const notify = (options: SnackbarNotificationOptions) => {
    message.value = options.message;
    caption.value = options.caption || '';
    color.value = options.color || 'blue';
    timeout.value = options.timeout || 5000;
    show.value = true;
  };

  const error = (options: SnackbarErrorOptions) => {
    const errorMessage: string = `
      MESSAGE: ${options.error instanceof Error ? options.error.message : 'Unknown Error'}
      STRING MESSAGE: ${typeof options.error === 'string' ? options.error : 'Unknown'} 
      STACK: ${options.error instanceof Error && 'stack' in options.error ? options.error.stack : 'Unknown'}
      CAUSE: ${options.error instanceof Error && 'cause' in options.error ? options.error.cause : 'Unknown'}
    `
    message.value = options.error instanceof Error ? options.error.message : typeof options.error === 'string' ? options.error : 'Unknown Error';
    caption.value = options.caption || 'See console for more information';
    color.value = options.color || 'red';
    timeout.value = options.timeout || 5000;
    show.value = true;

    console.error(errorMessage);
  };

  const service: NotificationService = {
    show,
    message,
    caption,
    color,
    timeout,
    notify,
    error
  };

  provide(NotificationSymbol, service);
  return service;
};

// Composable to use the notification service anywhere
export const useNotification = () => {
  const notification = inject(NotificationSymbol);
  if (!notification) {
    throw new Error('Notification service not provided');
  }
  return notification;
};