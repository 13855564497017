import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/img/pca_white_headerlogo.png'


const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = {
  class: "footer-section ml-6",
  style: {"margin-top":"-12px"}
}
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "d-flex flex-column justify-center align-center footer-section" }
const _hoisted_5 = { class: "footer-text secondary-font" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_footer = _resolveComponent("v-footer")!

  return (_openBlock(), _createBlock(_component_v_footer, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "footer-section" }, [
          _createElementVNode("h3", {
            class: "footer-text secondary-font",
            style: {"font-weight":"bold","text-transform":"uppercase"}
          }, " Contact PCA "),
          _createElementVNode("div", { class: "footer-text secondary-font" }, "PCA National Headquarters"),
          _createElementVNode("div", { class: "footer-text secondary-font" }, "P.O. Box 6400"),
          _createElementVNode("div", { class: "footer-text secondary-font" }, "Columbia, MD 21045")
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("a", {
            href: 'tel:' + _ctx.phoneNumber,
            class: "footer-text secondary-font",
            style: {"font-weight":"bold"}
          }, " +1 410 381 0911 ", 8, _hoisted_3),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "footer-text secondary-font" }, "Physical Address:", -1)),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "footer-text secondary-font" }, "9689 Gerwig Lane, Unit 4C/D", -1)),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "footer-text secondary-font" }, "Columbia, MD 21046", -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socialLinks, (link, index) => {
            return (_openBlock(), _createBlock(_component_v_btn, {
              href: link.url,
              key: index,
              icon: "",
              class: "text-white",
              target: "_blank",
              variant: "text"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(link.icon), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["href"]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_5, " © " + _toDisplayString(_ctx.currentYear) + " The Porsche Club of America Inc. All rights reserved ", 1)
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("img", {
        src: _imports_0,
        alt: "PCA Logo",
        class: "footer-pca-logo"
      }, null, -1))
    ]),
    _: 1
  }))
}