<template>
  <div class="loading-container">
    <h1 class="loading-title">2025 PCA Parade</h1>
    <img
      src="@/assets/gif/wheel_spinner_loading.gif"
      alt="wheel spinning loading"
      class="mx-auto"
    />
    <p class="loading-caption">Loading...</p>
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LoadingCarWheelSpinner',
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss">
.loading-container {
  align-items: center;
  background-color: $secondary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.loading-title {
  color: $pca-blue;
  font-family: 'Saira Condensed', sans-serif;
  font-size: 50px;
  font-weight: 600;
}

.loading-caption {
  color: $pca-dark-grey;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 600;
}
</style>
