// Stores
import { useAppDataStore } from './appDataStore';

// Types
import {
  CompletionProgress,
  CompletionProgressFormType,
  EntrantItem,
  FormType,
  MemberDetails,
  PaymentSummary,
  PrimaryEntrantDetails,
  RegisteredEntrantsHeadcount,
  TotalsByRegistrationFormType,
} from '@/types/user';
import {
  CompletionProgressResponse,
  PaymentSummaryResponse,
  PaymentSummaryResponseItem,
  PrimaryEntrantDetailsResponse,
  RegisteredEntrantsHeadcountResponse,
} from '@/types/apiResponses';

// Utils
import { defineStore } from 'pinia';
import { formatPrice } from '@/utils/dollaDollaBillzYall';
import {
  fetchCompletionProgress,
  fetchPaymentSummary,
  fetchPrimaryEntrantDetails,
  fetchRegisteredEntrantsHeadcount,
} from '@/api/apiCalls';

// Local types
type UserDataStoreState = {
  memberDetails: MemberDetails;
  primaryEntrantDetails: PrimaryEntrantDetails | null;
  registeredEntrantsHeadcount: RegisteredEntrantsHeadcount;
  completionProgress: CompletionProgress;
  paymentSummary: PaymentSummary;
};

export const useUserDataStore = defineStore('userDataStore', {
  state: (): UserDataStoreState => ({
    memberDetails: {} as MemberDetails,
    primaryEntrantDetails: null,
    registeredEntrantsHeadcount: {
      [FormType.PRIMARY_ENTRANT]: 0,
      [FormType.CO_ENTRANT]: 0,
      [FormType.CHILD]: 0,
      [FormType.GUEST]: 0,
      [FormType.PCA_JUNIOR]: 0,
    },
    completionProgress: {
      [CompletionProgressFormType.CHECKOUT]: false,
      [CompletionProgressFormType.PCA_JUNIOR_OR_CHILD]: false,
      [CompletionProgressFormType.COENTRANT]: false,
      [CompletionProgressFormType.GUEST]: false,
      [CompletionProgressFormType.PRIMARYENTRANT]: false,
    },
    // paymentSummary: {
    //   [FormType.PRIMARY_ENTRANT]: [],
    //   [FormType.CO_ENTRANT]: [],
    //   [FormType.CHILD]: [],
    //   [FormType.GUEST]: [],
    //   [FormType.PCA_JUNIOR]: [],
    // },
    paymentSummary: {
      [FormType.PRIMARY_ENTRANT]: [
        { id: 1, firstName: 'John', lastName: 'Smith', paid: true, price: 50 },
      ],
      [FormType.CO_ENTRANT]: [
        { id: 2, firstName: 'Bob', lastName: 'Saget', paid: true, price: 50 },
      ],
      [FormType.CHILD]: [
        { id: 3, firstName: 'Jason', lastName: 'Armstrong', paid: true, price: 50 },
        { id: 4, firstName: 'Hannah', lastName: 'Barbara', paid: false, price: 50 },
      ],
      [FormType.GUEST]: [
        { id: 5, firstName: 'Brian', lastName: 'Long', paid: true, price: 50 },
        { id: 6, firstName: 'Erik', lastName: 'Lamar', paid: false, price: 50 },
      ],
      [FormType.PCA_JUNIOR]: [
        { id: 7, firstName: 'Joey', lastName: 'Woods', paid: true, price: 50 },
        { id: 8, firstName: 'Sarah', lastName: 'Smile', paid: false, price: 50 },
      ],
      // @ZACK ^ for testing - remove when done!
    },
  }),

  getters: {
    /**
     * @name getPaymentSummary
     * @description Returns the paymentSummary
     */
    getPaymentSummary(): UserDataStoreState['paymentSummary'] {
      return this.paymentSummary;
    },

    /**
     * @name getMemberDetails
     * @description Returns the memberDetails
     */
    getMemberDetails(): UserDataStoreState['memberDetails'] {
      return this.memberDetails;
    },

    /**
     * @name getMemberNumber
     * @description Returns the PCA member number
     */
    getMemberNumber(): UserDataStoreState['memberDetails']['MEMBERNUMBER'] {
      return this.memberDetails.MEMBERNUMBER;
    },

    /**
     * @name getPrimaryEntrantDetails
     * @description Returns the primaryEntrantDetails
     */
    getPrimaryEntrantDetails(): UserDataStoreState['primaryEntrantDetails'] {
      return this.primaryEntrantDetails;
    },

    /**
     * @name getRegisteredEntrantsHeadcount
     * @description Returns the registeredEntrantsHeadcount
     */
    getRegisteredEntrantsHeadcount(): UserDataStoreState['registeredEntrantsHeadcount'] {
      return this.registeredEntrantsHeadcount;
    },

    /**
     * @name getBalanceDue
     * @description Computes the balance due for unpaid entrants
     */
    getBalanceDue(): string {
      let balanceDue: number = 0;
      for (const formType in this.paymentSummary) {
        for (const entrantItem of this.paymentSummary[formType as keyof PaymentSummary]) {
          if (!entrantItem.paid) {
            balanceDue += entrantItem.price;
          }
        }
      }
      return formatPrice(balanceDue);
    },

    /**
     * @name getTotalPaid
     * @description Computes the total paid to date
     */
    getTotalPaid(): string {
      let totalPaid: number = 0;
      for (const formType in this.paymentSummary) {
        for (const entrantItem of this.paymentSummary[formType as keyof PaymentSummary]) {
          if (entrantItem.paid) {
            totalPaid += entrantItem.price;
          }
        }
      }
      return formatPrice(totalPaid);
    },

    /**
     * @name getCompletionProgress
     * @description Returns the completionProgress
     */
    getCompletionProgress(): UserDataStoreState['completionProgress'] {
      return this.completionProgress;
    },

    /**
     * @name getCheckoutComplete
     * @description Returns true if the checkout form is complete (user has paid)
     */
    getCheckoutComplete(): boolean {
      return (
        this.getPrimaryEntrantDetails !== null && this.getPrimaryEntrantDetails.PHASE1PAID === 1
      );
    },

    /**
     * @name getEntrantNumber
     * @description Returns the entrant number if checkout is complete - else returns null
     */
    getEntrantNumber(): string | null {
      return this.getCheckoutComplete &&
        this.getPrimaryEntrantDetails !== null &&
        this.getPrimaryEntrantDetails.ENTRANT_NUMBER !== null
        ? this.getPrimaryEntrantDetails.ENTRANT_NUMBER
        : null;
    },

    /**
     * @name getCompletionProgressPercentage
     * @description Returns the percentage of forms completed
     */
    getCompletionProgressPercentage(): number {
      let percentage: number = 0;
      for (const isComplete of Object.values(this.completionProgress)) {
        if (isComplete) {
          percentage += 20;
        }
      }
      return percentage;
    },

    /**
     * @name getRegistrationFormTotals
     * @description Returns the totals for each registration form type
     */
    // getRegistrationFormTotals(): TotalsByRegistrationFormType {
    //   const appDataStore = useAppDataStore();
    //   return {
    //     [FormType.PRIMARY_ENTRANT]:
    //       this.registeredEntrantsHeadcount[FormType.PRIMARY_ENTRANT] *
    //       appDataStore.getRegistrationPrices.base,
    //     [FormType.CO_ENTRANT]:
    //       this.registeredEntrantsHeadcount[FormType.CO_ENTRANT] *
    //       appDataStore.getRegistrationPrices.coEntrant,
    //     [FormType.CHILD]:
    //       this.registeredEntrantsHeadcount[FormType.CHILD] *
    //       appDataStore.getRegistrationPrices.cafp, // @ZACK - is this correct? Looks like all kid things are $30
    //     [FormType.GUEST]:
    //       this.registeredEntrantsHeadcount[FormType.GUEST] *
    //       appDataStore.getRegistrationPrices.adult,
    //     [FormType.PCA_JUNIOR]:
    //       this.registeredEntrantsHeadcount[FormType.PCA_JUNIOR] *
    //       appDataStore.getRegistrationPrices.jpp,
    //   };
    // },

    /**
     * @name getGrandTotal
     * @description Returns the grand total (sum of all registration form totals)
     */
    // getGrandTotal(): number {
    //   let grandTotal: number = 0;
    //   for (const formType in this.getRegistrationFormTotals) {
    //     grandTotal +=
    //       this.getRegistrationFormTotals[formType as keyof TotalsByRegistrationFormType];
    //   }
    //   return grandTotal;
    // },
  },

  actions: {
    setMemberDetailsData(data: UserDataStoreState['memberDetails']): void {
      this.memberDetails = data;
    },

    setPrimaryEntrantDetailsData(data: UserDataStoreState['primaryEntrantDetails']): void {
      this.primaryEntrantDetails = data;
    },

    setPaymentSummaryData(data: PaymentSummaryResponse): void {
      const appDataStore = useAppDataStore();
      const paymentSummary: PaymentSummary = {
        [FormType.PRIMARY_ENTRANT]: [],
        [FormType.CO_ENTRANT]: [],
        [FormType.CHILD]: [],
        [FormType.GUEST]: [],
        [FormType.PCA_JUNIOR]: [],
      };
      for (const formType in data) {
        if (formType === 'PRIMARY_ENTRANT' && Object.keys(data[formType]).length > 0) {
          paymentSummary[FormType.PRIMARY_ENTRANT] = [
            {
              id: data[formType].ID,
              firstName: data[formType].FIRSTNAME,
              lastName: data[formType].LASTNAME,
              paid: data[formType].PAID === 1 ? true : false,
              price: appDataStore.getRegistrationPrices.base,
            },
          ];
        }
        if (formType === 'CO_ENTRANT' && Object.keys(data[formType]).length > 0) {
          paymentSummary[FormType.CO_ENTRANT] = [
            {
              id: data[formType].ID,
              firstName: data[formType].FIRSTNAME,
              lastName: data[formType].LASTNAME,
              paid: data[formType].PAID === 1 ? true : false,
              price: appDataStore.getRegistrationPrices.coEntrant,
            },
          ];
        }
        if (formType === 'CHILD') {
          paymentSummary[FormType.CHILD] = (
            data[formType as keyof PaymentSummaryResponse] as PaymentSummaryResponse['CHILD']
          ).map((item: PaymentSummaryResponseItem) => ({
            id: item.ID,
            firstName: item.FIRSTNAME,
            lastName: item.LASTNAME,
            paid: item.PAID === 1 ? true : false,
            price: appDataStore.getRegistrationPrices.cafp,
          }));
        }
        if (formType === 'GUEST') {
          paymentSummary[FormType.GUEST] = (
            data[formType as keyof PaymentSummaryResponse] as PaymentSummaryResponse['GUEST']
          ).map((item: PaymentSummaryResponseItem) => ({
            id: item.ID,
            firstName: item.FIRSTNAME,
            lastName: item.LASTNAME,
            paid: item.PAID === 1 ? true : false,
            price: appDataStore.getRegistrationPrices.adult,
          }));
        }
        if (formType === 'PCA_JUNIOR') {
          paymentSummary[FormType.PCA_JUNIOR] = (
            data[formType as keyof PaymentSummaryResponse] as PaymentSummaryResponse['PCA_JUNIOR']
          ).map((item: PaymentSummaryResponseItem) => ({
            id: item.ID,
            firstName: item.FIRSTNAME,
            lastName: item.LASTNAME,
            paid: item.PAID === 1 ? true : false,
            price: appDataStore.getRegistrationPrices.jpp,
          }));
        }
      }
      this.paymentSummary = paymentSummary;
      // @ZACK - put this back when done testing!
      // ****************************************
      // ****************************************
      // ****************************************
    },

    setCompletionProgress(completionProgressResponse: CompletionProgressResponse): void {
      const completionProgress: CompletionProgress = {
        [CompletionProgressFormType.CHECKOUT]: false,
        [CompletionProgressFormType.PCA_JUNIOR_OR_CHILD]: false,
        [CompletionProgressFormType.COENTRANT]: false,
        [CompletionProgressFormType.GUEST]: false,
        [CompletionProgressFormType.PRIMARYENTRANT]: false,
      };
      for (const formType in completionProgressResponse) {
        if (formType === CompletionProgressFormType.PRIMARYENTRANT) {
          const isComplete: boolean | undefined =
            completionProgressResponse[CompletionProgressFormType.PRIMARYENTRANT] === 0
              ? false
              : true;
          if (isComplete === undefined) {
            throw new Error(
              'setCompletionProgress: Missing PRIMARYENTRANT value from CompletionProgressResponse'
            );
          }
          completionProgress[CompletionProgressFormType.PRIMARYENTRANT] = isComplete;
        }
        if (formType === CompletionProgressFormType.COENTRANT) {
          const isComplete: boolean | undefined =
            completionProgressResponse[CompletionProgressFormType.COENTRANT] === 0 ? false : true;
          if (isComplete === undefined) {
            throw new Error(
              'setCompletionProgress: Missing COENTRANT value from CompletionProgressResponse'
            );
          }
          completionProgress[CompletionProgressFormType.COENTRANT] = isComplete;
        }
        if (formType === CompletionProgressFormType.PCA_JUNIOR_OR_CHILD) {
          const isComplete: boolean | undefined =
            completionProgressResponse[CompletionProgressFormType.PCA_JUNIOR_OR_CHILD] === 0
              ? false
              : true;
          if (isComplete === undefined) {
            throw new Error(
              'setCompletionProgress: Missing PCA_JUNIOR_OR_CHILD value from CompletionProgressResponse'
            );
          }
          completionProgress[CompletionProgressFormType.PCA_JUNIOR_OR_CHILD] = isComplete;
        }
        if (formType === CompletionProgressFormType.GUEST) {
          const isComplete: boolean | undefined =
            completionProgressResponse[CompletionProgressFormType.GUEST] === 0 ? false : true;
          if (isComplete === undefined) {
            throw new Error(
              'setCompletionProgress: Missing GUEST value from CompletionProgressResponse'
            );
          }
          completionProgress[CompletionProgressFormType.GUEST] = isComplete;
        }
        if (formType === CompletionProgressFormType.CHECKOUT) {
          const isComplete: boolean | undefined =
            completionProgressResponse[CompletionProgressFormType.CHECKOUT] === 0 ? false : true;
          if (isComplete === undefined) {
            throw new Error(
              'setCompletionProgress: Missing CHECKOUT value from CompletionProgressResponse'
            );
          }
          completionProgress[CompletionProgressFormType.CHECKOUT] = isComplete;
        }
      }
      this.completionProgress = completionProgress;
    },

    setRegisteredEntrantsHeadcount(data: RegisteredEntrantsHeadcountResponse): void {
      const headcounts: RegisteredEntrantsHeadcount = {
        [FormType.PRIMARY_ENTRANT]: 0,
        [FormType.CO_ENTRANT]: 0,
        [FormType.CHILD]: 0,
        [FormType.GUEST]: 0,
        [FormType.PCA_JUNIOR]: 0,
      };
      for (const headcountItem of data) {
        if (headcountItem.ENTRANT_TYPE_NAME === 'entrant') {
          headcounts[FormType.PRIMARY_ENTRANT] = headcountItem.COUNT;
        }
        if (headcountItem.ENTRANT_TYPE_NAME === 'jpp') {
          headcounts[FormType.PCA_JUNIOR] = headcountItem.COUNT;
        }
        if (headcountItem.ENTRANT_TYPE_NAME === 'co-entrant') {
          headcounts[FormType.CO_ENTRANT] = headcountItem.COUNT;
        }
        if (headcountItem.ENTRANT_TYPE_NAME === 'child') {
          headcounts[FormType.CHILD] = headcountItem.COUNT;
        }
        if (headcountItem.ENTRANT_TYPE_NAME === 'guest') {
          headcounts[FormType.GUEST] = headcountItem.COUNT;
        }
      }
      this.registeredEntrantsHeadcount = headcounts;
    },

    async requestPrimaryEntrantDetails(): Promise<void> {
      const primaryEntrantDetailsResponse: PrimaryEntrantDetailsResponse | null =
        await fetchPrimaryEntrantDetails(this.getMemberNumber);
      this.setPrimaryEntrantDetailsData(primaryEntrantDetailsResponse);
    },

    async requestPaymentSummary(): Promise<void> {
      const paymentSummaryResponse: PaymentSummaryResponse = await fetchPaymentSummary();
      this.setPaymentSummaryData(paymentSummaryResponse);
    },

    async requestRegisteredEntrantsHeadcount(): Promise<void> {
      const registeredEntrantsHeadcountResponse: RegisteredEntrantsHeadcountResponse =
        await fetchRegisteredEntrantsHeadcount(this.getMemberNumber);
      this.setRegisteredEntrantsHeadcount(registeredEntrantsHeadcountResponse);
    },

    async requestCompletionProgress(): Promise<void> {
      const completionProgressResponse: CompletionProgressResponse | null =
        await fetchCompletionProgress();
      if (completionProgressResponse !== null) {
        this.setCompletionProgress(completionProgressResponse);
      }
      // The completion progress endpoint returns null if there is 0% completion
      // If that's the case, we don't need to do anything, just leave the defaults as-is
    },
  },
});
