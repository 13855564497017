// Types
import {
  CompletionProgressResponse,
  EntrantTypesResponse,
  EventDetailsResponse,
  GendersResponse,
  MemberDetailsResponse,
  SubmitPaymentResponse,
  PaymentSummaryResponse,
  PCAAgeRangesResponse,
  PrimaryEntrantDetailsResponse,
  RegionsResponse,
  RegisteredEntrantsHeadcountResponse,
} from '@/types/apiResponses';

// Utils
import { defineStore } from 'pinia';
import {
  fetchCompletionProgress,
  fetchEntrantTypes,
  fetchEventDetails,
  fetchGenders,
  fetchMemberDetails,
  fetchPaymentSummary,
  fetchPCAAgeRanges,
  fetchPrimaryEntrantDetails,
  fetchRegions,
  fetchRegisteredEntrantsHeadcount,
} from '@/api/apiCalls';

// Vue
import { ref } from 'vue';

export const useInitDataStore = defineStore('initDataStore', () => {
  // State

  const registeredEntrantsHeadcount = ref<RegisteredEntrantsHeadcountResponse>([]);
  const appLoaded = ref<boolean>(false);
  const regions = ref<RegionsResponse>([]);
  const completionProgress = ref<CompletionProgressResponse | null>({});
  const entrantTypes = ref<EntrantTypesResponse>([] as unknown as EntrantTypesResponse);
  const pcaAgeRanges = ref<PCAAgeRangesResponse>([] as unknown as PCAAgeRangesResponse);
  const genders = ref<GendersResponse>([] as unknown as GendersResponse);
  const memberDetails = ref<MemberDetailsResponse>({} as MemberDetailsResponse);
  const eventDetails = ref<EventDetailsResponse>({} as EventDetailsResponse);
  const primaryEntrantDetails = ref<PrimaryEntrantDetailsResponse | null>(null);
  const paymentSummary = ref<PaymentSummaryResponse>({} as PaymentSummaryResponse);

  // Init store method
  const initStore = async (): Promise<void> => {
    await Promise.all([
      fetchMemberDetails(2010060590),
      fetchPrimaryEntrantDetails(2010060590),
      fetchRegisteredEntrantsHeadcount(2010060590),
      fetchCompletionProgress(),
      fetchPaymentSummary(),
      fetchEventDetails(),
      fetchRegions(),
      fetchEntrantTypes(),
      fetchPCAAgeRanges(),
      fetchGenders(),
    ])
      .then(
        ([
          memberDetailsResponse,
          primaryEntrantDetailsResponse,
          registeredEntrantsHeadcountResponse,
          completionProgressResponse,
          paymentSummaryResponse,
          eventDetailsResponse,
          regionsResponse,
          entrantTypesResponse,
          pcaAgeRangesResponse,
          gendersResponse,
        ]) => {
          memberDetails.value = memberDetailsResponse;
          primaryEntrantDetails.value = primaryEntrantDetailsResponse;
          registeredEntrantsHeadcount.value = registeredEntrantsHeadcountResponse;
          completionProgress.value = completionProgressResponse;
          paymentSummary.value = paymentSummaryResponse;
          eventDetails.value = eventDetailsResponse;
          regions.value = regionsResponse;
          entrantTypes.value = entrantTypesResponse;
          pcaAgeRanges.value = pcaAgeRangesResponse;
          genders.value = gendersResponse;
          appLoaded.value = true;
        }
      )
      .catch((error) => {
        throw error;
      });
  };

  // Initialize initDataStore
  initStore();

  return {
    publicProps: {
      appLoaded,
      completionProgress,
      entrantTypes,
      eventDetails,
      genders,
      memberDetails,
      paymentSummary,
      pcaAgeRanges,
      primaryEntrantDetails,
      regions,
      registeredEntrantsHeadcount,
    },
    privateProps: {},
  };
});
