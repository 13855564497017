import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/gif/wheel_spinner_loading.gif'


const _hoisted_1 = { class: "loading-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("h1", { class: "loading-title" }, "2025 PCA Parade", -1),
    _createElementVNode("img", {
      src: _imports_0,
      alt: "wheel spinning loading",
      class: "mx-auto"
    }, null, -1),
    _createElementVNode("p", { class: "loading-caption" }, "Loading...", -1)
  ])))
}