// Types
import {
  ClearEntrantsResponse,
  CoEntrantOrGuestPCAMemberDetailsResponse,
  CompletionProgressResponse,
  DeleteUnpaidEntrantResponse,
  EntrantTypesResponse,
  EventDetailsResponse,
  GendersResponse,
  LoginUserResponse,
  MemberDetailsResponse,
  PaymentSummaryResponse,
  PCAAgeRangesResponse,
  PCAJuniorDetailsResponse,
  PrimaryEntrantDetailsResponse,
  RegionsResponse,
  RegisteredEntrantsHeadcountResponse,
  SaveChildResponse,
  SaveCoEntrantResponse,
  SaveCompletionProgressResponse,
  SaveGuestResponse,
  SavePCAJuniorResponse,
  SavePrimaryEntrantResponse,
  SubmitPaymentResponse,
  SubmitContactUsMessageResponse,
} from '@/types/apiResponses';
import {
  FetchDeleteUnpaidEntrantRequestBody,
  FetchLoginUserRequestBody,
  FetchSaveChildRequestBody,
  FetchSaveCoEntrantRequestBody,
  FetchSaveGuestRequestBody,
  FetchSavePCAJuniorRequestBody,
  FetchSavePrimaryEntrantRequestBody,
  FetchSubmitPaymentRequestBody,
  FetchSubmitContactUsMessageRequestBody,
} from '@/types/apiRequests';
import { CompletionProgressFormType } from '@/types/user';

// Utils
import axios from 'axios';

const thekey: string = 'DFF9502BB08A04ED2C3D06D7537C54ADE9040DF3D3EEAEDECDA525385127F1D5';
const handlerURL: string = 'https://dev.blinex.com/Parade/server/index.cfm?event=';
const memberDetails: string = 'member.getMemberDetails';
const juniorDetails: string = 'member.getJuniorsDetails';
const primaryEntrantDetails: string = 'member.getEntrantDetails';
const coEntrantOrGuestPCAMemberDetails: string = 'member.getCoEntrantDetails';
const paymentSummary: string = 'member.paymentSummary';
const eventSettings: string = 'main.getEventSettings';
const regions: string = 'main.getRegions';
const entrantTypes: string = 'main.getEntrantTypes';
const ageRanges: string = 'main.getAgeRanges';
const genders: string = 'main.getGenders';
const submitContactUsMessage: string = 'member.submitContactUsMessage';
const submitPayment: string = 'member.entrantPayment';
const registeredEntrantsHeadCount: string = 'member.headCount';
const clearEntrants: string = 'member.clearEntrant';
const saveCompletionProgress: string = 'member.updateProgress';
const completionProgress: string = 'member.getProgress';
const savePrimaryEntrant: string = 'member.primaryEntrantSave';
const saveCoEntrant: string = 'member.coEntrantSave';
const saveGuest: string = 'member.guestSave';
const savePCAJunior: string = 'member.juniorSave';
const saveChild: string = 'member.saveChild';
const deleteUnpaidEntrant: string = 'member.removeUnpaidEntrant';
const loginUser: string = 'member.login';

export const fetchLoginUser = async (
  requestBody: FetchLoginUserRequestBody
): Promise<LoginUserResponse> => {
  const response = await axios.post(`${handlerURL}${loginUser}`, requestBody);
  if ('data' in response && 'status' in response.data && 'msg' in response.data) {
    return response.data;
  } else {
    throw new Error(`Invalid login user response from ${loginUser} endpoint`);
  }
};

export const fetchDeleteUnpaidEntrant = async (
  requestBody: FetchDeleteUnpaidEntrantRequestBody
): Promise<DeleteUnpaidEntrantResponse> => {
  const response = await axios.post(`${handlerURL}${deleteUnpaidEntrant}`, {
    thekey,
    ...requestBody,
  });
  if ('data' in response) {
    return response.data;
  } else {
    throw new Error(`Invalid delete unpaid entrant response from ${deleteUnpaidEntrant} endpoint`);
  }
};

export const fetchSubmitContactUsMessage = async (
  requestBody: FetchSubmitContactUsMessageRequestBody
): Promise<SubmitContactUsMessageResponse> => {
  const response = await axios.post(`${handlerURL}${submitContactUsMessage}`, {
    thekey,
    ...requestBody,
  });
  if ('data' in response) {
    return response.data;
  } else {
    throw new Error(
      `Invalid submit contact us message response from ${submitContactUsMessage} endpoint`
    );
  }
};

export const fetchSubmitPayment = async (
  memberNumber: number,
  requestBody: FetchSubmitPaymentRequestBody
): Promise<SubmitPaymentResponse> => {
  const response = await axios.post(`${handlerURL}${submitPayment}`, {
    thekey,
    membernumber: memberNumber,
    ...requestBody,
  });
  if ('data' in response) {
    return response.data;
  } else {
    throw new Error(`Invalid submit payment response from ${submitPayment} endpoint`);
  }
};

export const fetchSaveCompletionProgress = async (
  formTypeToMarkComplete: CompletionProgressFormType
): Promise<SaveCompletionProgressResponse> => {
  const response = await axios.post(`${handlerURL}${saveCompletionProgress}`, {
    thekey,
    progressval: formTypeToMarkComplete,
  });
  if ('data' in response) {
    return response.data;
  } else {
    throw new Error(`Invalid save primary response from ${saveCoEntrant} endpoint`);
  }
};

// This endpoint returns `null` if there is 0% completion - otherwise it returns the CompletionProgressResponse object
export const fetchCompletionProgress = async (): Promise<CompletionProgressResponse | null> => {
  const response = await axios.post(`${handlerURL}${completionProgress}`, {
    thekey,
  });
  if (Array.isArray(response.data) && response.data[0] !== undefined) {
    return response.data[0];
  } else if (Array.isArray(response.data) && response.data.length === 0) {
    return null;
  } else {
    throw new Error(`Invalid completion progress response from ${completionProgress} endpoint`);
  }
};

export const fetchSavePrimaryEntrant = async (
  memberNumber: number,
  requestBody: FetchSavePrimaryEntrantRequestBody
): Promise<SavePrimaryEntrantResponse> => {
  const response = await axios.post(`${handlerURL}${savePrimaryEntrant}`, {
    thekey,
    membernumber: memberNumber,
    ...requestBody,
  });
  if ('data' in response && 'data' in response.data && response.data.data === 1) {
    return response.data;
  } else {
    throw new Error(`Invalid save primary response from ${saveCoEntrant} endpoint`);
  }
};

export const fetchSaveCoEntrant = async (
  requestBody: FetchSaveCoEntrantRequestBody
): Promise<SaveCoEntrantResponse> => {
  const response = await axios.post(`${handlerURL}${saveCoEntrant}`, {
    thekey,
    ...requestBody,
  });
  if ('data' in response && 'data' in response.data && response.data.data === 1) {
    return response.data;
  } else {
    throw new Error(`Invalid save co-entrant response from ${saveCoEntrant} endpoint`);
  }
};

export const fetchSaveGuest = async (
  requestBody: FetchSaveGuestRequestBody
): Promise<SaveGuestResponse> => {
  const response = await axios.post(`${handlerURL}${saveGuest}`, {
    thekey,
    ...requestBody,
  });
  if ('data' in response && 'data' in response.data && response.data.data === 1) {
    return response.data;
  } else {
    throw new Error(`Invalid save guest response from ${saveGuest} endpoint`);
  }
};

export const fetchSavePCAJunior = async (
  memberNumber: number,
  requestBody: FetchSavePCAJuniorRequestBody
): Promise<SavePCAJuniorResponse> => {
  const response = await axios.post(`${handlerURL}${savePCAJunior}`, {
    thekey,
    membernumber: memberNumber,
    ...requestBody,
  });
  if ('data' in response && 'data' in response.data && response.data.data === 1) {
    return response.data;
  } else {
    throw new Error(`Invalid save PCA Junior response from ${saveCoEntrant} endpoint`);
  }
};

export const fetchSaveChild = async (
  memberNumber: number,
  requestBody: FetchSaveChildRequestBody
): Promise<SaveChildResponse> => {
  const response = await axios.post(`${handlerURL}${saveChild}`, {
    thekey,
    membernumber: memberNumber,
    ...requestBody,
  });
  if ('data' in response && 'data' in response.data && response.data.data === 1) {
    return response.data;
  } else {
    throw new Error(`Invalid save child response from ${saveChild} endpoint`);
  }
};

export const fetchClearEntrants = async (): Promise<ClearEntrantsResponse> => {
  const response = await axios.post(`${handlerURL}${clearEntrants}`, {
    thekey,
  });
  if ('data' in response && 'data' in response.data && response.data.data === 1) {
    return response.data;
  } else {
    throw new Error(`Invalid clear entrants response from ${clearEntrants} endpoint`);
  }
};

export const fetchMemberDetails = async (memberNumber: number): Promise<MemberDetailsResponse> => {
  const response = await axios.post(`${handlerURL}${memberDetails}`, {
    thekey,
    membernumber: memberNumber,
  });
  if (Array.isArray(response.data) && response.data[0] !== undefined) {
    return response.data[0];
  } else {
    throw new Error(`Invalid member details response from ${memberDetails} endpoint`);
    // @ZACK - all these validations could be more robust if we checked for each expected field
  }
};

export const fetchRegisteredEntrantsHeadcount = async (
  memberNumber: number
): Promise<RegisteredEntrantsHeadcountResponse> => {
  const response = await axios.post(`${handlerURL}${registeredEntrantsHeadCount}`, {
    thekey,
    membernumber: memberNumber,
  });
  if (Array.isArray(response.data)) {
    return response.data;
  } else {
    throw new Error(
      `Invalid registered entrants head count response from ${registeredEntrantsHeadCount} endpoint`
    );
  }
};

export const fetchPrimaryEntrantDetails = async (
  memberNumber: number
): Promise<PrimaryEntrantDetailsResponse | null> => {
  const response = await axios.post(`${handlerURL}${primaryEntrantDetails}`, {
    thekey,
    membernumber: memberNumber,
  });
  if (Array.isArray(response.data) && response.data[0] !== undefined) {
    return response.data[0];
  } else if (Array.isArray(response.data) && response.data.length === 0) {
    return null;
  } else {
    throw new Error(
      `Invalid primary entrant details response from ${primaryEntrantDetails} endpoint`
    );
  }
};

export const fetchPaymentSummary = async (): Promise<PaymentSummaryResponse> => {
  const response = await axios.post(`${handlerURL}${paymentSummary}`, {
    thekey,
  });
  if ('data' in response) {
    return response.data;
  } else {
    throw new Error(`Invalid payment summary response from ${paymentSummary} endpoint`);
  }
};

export const fetchCoEntrantOrGuestPCAMemberDetails = async (
  coEntrantOrGuestPCAMemberNumber: number
): Promise<CoEntrantOrGuestPCAMemberDetailsResponse> => {
  const response = await axios.post(`${handlerURL}${coEntrantOrGuestPCAMemberDetails}`, {
    thekey,
    membernumber: coEntrantOrGuestPCAMemberNumber,
  });
  if (Array.isArray(response.data) && response.data[0] !== undefined) {
    return response.data[0];
  } else {
    throw new Error(
      `Invalid co-entrant details response from ${coEntrantOrGuestPCAMemberDetails} endpoint`
    );
  }
};

export const fetchJuniorDetails = async (
  associatedMemberNumber: number
): Promise<PCAJuniorDetailsResponse> => {
  const response = await axios.post(`${handlerURL}${juniorDetails}`, {
    thekey,
    membernumber: associatedMemberNumber,
  });
  if (Array.isArray(response.data)) {
    return response.data;
  } else {
    throw new Error(`Invalid junior details response from ${juniorDetails} endpoint`);
  }
};

export const fetchEventDetails = async (): Promise<EventDetailsResponse> => {
  const response = await axios.post(`${handlerURL}${eventSettings}`, { thekey });
  if (Array.isArray(response.data) && response.data.length > 0) {
    return response.data[0];
  } else {
    throw new Error(`Invalid event details response from ${eventSettings} endpoint`);
  }
};

export const fetchRegions = async (): Promise<RegionsResponse> => {
  const response = await axios.post(`${handlerURL}${regions}`, { thekey });
  if (Array.isArray(response.data) && response.data.length > 0) {
    return response.data.slice(1); // The item at index 0 is null for some reason - get 'er out of there!
  } else {
    throw new Error(`Invalid regions response from ${regions} endpoint`);
  }
};

export const fetchEntrantTypes = async (): Promise<EntrantTypesResponse> => {
  const response = await axios.post(`${handlerURL}${entrantTypes}`, { thekey });
  if (Array.isArray(response.data) && response.data.length > 0) {
    return response.data as EntrantTypesResponse;
  } else {
    throw new Error(`Invalid entrant types response from ${entrantTypes} endpoint`);
  }
};

export const fetchPCAAgeRanges = async (): Promise<PCAAgeRangesResponse> => {
  const response = await axios.post(`${handlerURL}${ageRanges}`, { thekey });
  if (Array.isArray(response.data) && response.data.length > 0) {
    return response.data as PCAAgeRangesResponse;
  } else {
    throw new Error(`Invalid PCA age ranges response from ${ageRanges} endpoint`);
  }
};

export const fetchGenders = async (): Promise<GendersResponse> => {
  const response = await axios.post(`${handlerURL}${genders}`, { thekey });
  if (Array.isArray(response.data) && response.data.length > 0) {
    return response.data as GendersResponse;
  } else {
    throw new Error(`Invalid genders response from ${genders} endpoint`);
  }
};
