/* eslint-disable */
// Ignore eslint rules for this file

// Utils
import routes from './routes';

// Vue
import { createRouter, createWebHistory, RouteLocationNormalizedGeneric } from 'vue-router';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(
    to: RouteLocationNormalizedGeneric,
    from: RouteLocationNormalizedGeneric,
    savedPosition: any
  ) {
    // Always scroll to top
    return { top: 0 };

    // OR if you want to use saved position when using browser back/forward
    // return savedPosition || { top: 0 };
  },
});

export default router;
