// Components
import App from './App.vue';

// Pinia
import { createPinia } from 'pinia';

// Utils
import router from './router';

// Vue
import { createApp } from 'vue';

// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@mdi/font/css/materialdesignicons.css';

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          'pca-blue': '#00338d',
          'pca-dark-blue': '#091d5e',
          'pca-light-blue': '#7bb7e0',
          'pca-dark-red': '#560216',
          'pca-red': '#aa182c',
          'pca-dark-grey': '#7b868c',
          'pca-light-grey': '#dde9f1',
        },
      },
    },
  },
  defaults: {
    VSelect: {
      variant: 'outlined',
    },
    VTextField: {
      variant: 'outlined',
    },
  },
});

createApp(App).use(createPinia()).use(router).use(vuetify).mount('#app');
