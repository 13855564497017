<template>
  <LoadingCarWheelSpinner v-if="!initDataStore.publicProps.appLoaded" />
  <div v-if="mountApp">
    <div class="app-wrapper">
      <router-view />
      <v-snackbar
        v-model="notification.show"
        :color="notification.color"
        :timeout="notification.timeout"
      >
        <div class="secondary-font" style="font-size: 14px; font-weight: 600">
          {{ notification.message }}
        </div>
        <div
          v-if="notification.caption"
          class="secondary-font"
          style="font-size: 14px; font-weight: 600"
        >
          {{ notification.caption }}
        </div>
        <template v-slot:actions>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click="notification.show = false"
          />
        </template>
      </v-snackbar>
    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
// Components
import Footer from '@/components/shared/Footer.vue';
import LoadingCarWheelSpinner from '@/components/shared/LoadingCarWheelSpinner.vue';

// Stores
import { useInitDataStore } from '@/stores/initDataStore';
import { useUserDataStore } from '@/stores/userDataStore';
import { useAppDataStore } from '@/stores/appDataStore';

// Types
import { MemberDetails } from '@/types/user';
import { NotificationService } from '@/utils/notificationService';
import { RegionsResponse } from '@/types/apiResponses';

// Utils
import { createNotificationService } from '@/utils/notificationService';

// Vue
import { defineComponent, onMounted, reactive, ref, onUnmounted, watch } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    Footer,
    LoadingCarWheelSpinner,
  },
  setup() {
    // State

    const appDataStore = reactive(useAppDataStore());
    const userDataStore = reactive(useUserDataStore());
    const initDataStore = reactive(useInitDataStore());
    const mountApp = ref<boolean>(false);
    const notification = reactive<NotificationService>(
      createNotificationService()
    );

    // Load timeout configuration
    const LOAD_TIMEOUT = 4000;
    let loadTimeoutTimer: ReturnType<typeof setTimeout> | null = null;

    // Lifecycle

    onMounted((): void => {
      startLoadTimeout();
    });

    onUnmounted((): void=> {
      cancelLoadTimeout();
    });

    // Methods

    // I was experiencing issues where the BE endpoints would hang while trying to load the initial app data.
    // In those cases, a simple refresh would fix the issue. This is my temporary solution to force the page to 
    // refresh if all API calls haven't resolved set time. We need to identify the root cause of the
    // hanging, but for now this will work.
    const startLoadTimeout = (): void => {
      // Clear any existing timer
      if (loadTimeoutTimer) {
        clearTimeout(loadTimeoutTimer);
      }

      // Set a new timeout
      loadTimeoutTimer = setTimeout(() => {
        console.warn('App load timeout exceeded. Refreshing page...');

        // Force page reload after a short delay to show the console warning
        setTimeout((): void => {
          window.location.reload();
        }, 3000);
      }, LOAD_TIMEOUT);
    };

    // Cancel load timeout
    const cancelLoadTimeout = (): void => {
      if (loadTimeoutTimer) {
        clearTimeout(loadTimeoutTimer);
        loadTimeoutTimer = null;
      }
    };

    // Watchers

    watch(
      () => initDataStore.publicProps.appLoaded,
      (newVal) => {
        try {
          if (newVal) {
            // Cancel the load timeout
            cancelLoadTimeout();

            // Using the REGIONCODE we're provided for the MemberDetails, look up the corresponding REGIONID
            // (we'll need it for form submissions later)
            const regionAPIResponseItem: RegionsResponse[number] | undefined =
              initDataStore.publicProps.regions.find(
                (region: RegionsResponse[number]) =>
                  region.REGIONCODE ===
                  initDataStore.publicProps.memberDetails.REGIONCODE
              );
            if (regionAPIResponseItem === undefined) {
              throw new Error(
                `App.vue watcher: could not find a RegionsResponse item with REGIONCODE ${initDataStore.publicProps.memberDetails.REGIONCODE}`
              );
            }
            appDataStore.setOptionsData({
              regions: initDataStore.publicProps.regions,
              entrantTypes: initDataStore.publicProps.entrantTypes,
              eventDetails: initDataStore.publicProps.eventDetails,
              pcaAgeRanges: initDataStore.publicProps.pcaAgeRanges,
              genders: initDataStore.publicProps.genders,
            });
            userDataStore.setMemberDetailsData({
              ...initDataStore.publicProps.memberDetails,
              REGIONID: regionAPIResponseItem.REGIONID,
            } as MemberDetails);
            userDataStore.setPrimaryEntrantDetailsData(
              initDataStore.publicProps.primaryEntrantDetails
            );
            userDataStore.setRegisteredEntrantsHeadcount(
              initDataStore.publicProps.registeredEntrantsHeadcount
            );
            userDataStore.setPaymentSummaryData(
              initDataStore.publicProps.paymentSummary
            );
            if (initDataStore.publicProps.completionProgress !== null) {
              // The completion progress endpoint returns null if there is 0% completion
              // If that's the case, we don't need to do anything, just leave the defaults as-is
              userDataStore.setCompletionProgress(
                initDataStore.publicProps.completionProgress
              );
            }
            mountApp.value = true;
          }
        } catch (error) {
          notification.error({ error });
        }
      }
    );

    return { initDataStore, mountApp, notification };
  },
});
</script>

<style lang="scss">
.app-wrapper {
  background-color: $secondary;
  min-height: calc(100vh - 76px);
}

.form-btn-wrapper {
  max-width: 600px;
  width: 100%;
}

.form-btn {
  background-color: $pca-blue !important;
  color: white !important;
  font-family: 'Saira Condensed', sans-serif;
  font-weight: 600;
}

.required-asterisk {
  color: $pca-red;
  font-size: 16px;
  font-weight: 600;
}

.centered-page-card {
  border-radius: 20px !important;
  margin-bottom: 32px !important;
  max-width: 600px;
  padding: 20px !important;
  width: 100%;
}

.font-14 {
  font-size: 14px;
}

.font-18 {
  font-size: 18px;
}

.v-tooltip {
  .v-overlay__content {
    background-color: $pca-light-blue !important;
    color: white !important;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 12px !important;
    font-weight: 600;
    border-radius: 4px !important;
    padding: 4px 8px !important;
  }
}

.loading {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.primary-font {
  font-family: 'Saira Condensed', sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

.pca-blue-background {
  background-color: $pca-blue;
}

.secondary-font {
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

.secondary-font-14-600 {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.background-color-pca-blue {
  background-color: $pca-blue;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Saira Condensed', sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0px;
}

.form-card-title {
  color: $pca-blue;
  font-family: 'Saira Condensed', sans-serif;
  font-size: 25px;
  font-weight: 600;
}

.small-home-page-card-title {
  color: $pca-blue;
  font-family: 'Saira Condensed', sans-serif;
  font-size: 25px;
  font-weight: 500;
}

.v-btn {
  letter-spacing: 0px !important;
  text-transform: none !important;
}

.contact-us-text-button {
  background-color: transparent;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px !important;
  font-weight: 600 !important;
  height: 17px !important;
  margin: -3px 0px 0px 0px;
  text-decoration: underline !important;
  width: 80px !important;
}

.v-btn-toggle {
  font-family: 'Saira Condensed', sans-serif;
  height: 32px !important;
  width: 128px;
}

.v-checkbox .v-label {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.pca-blue {
  color: $pca-blue;
}

.pca-light-blue {
  color: $pca-light-blue;
}

.pca-dark-blue {
  color: $pca-dark-blue;
}

.pca-red {
  color: $pca-red;
}

.pca-dark-red {
  color: $pca-dark-red;
}

.pca-light-grey {
  color: $pca-light-grey;
}

.pca-dark-grey {
  color: $pca-dark-grey;
}

.dropdown-menu-content {
  .v-list-item {
    min-height: 32px !important;
    padding: 0 8px !important;
  }

  .v-list-item-title {
    font-size: 14px !important;
  }
}

@media (max-width: 1180px) {
  .small-home-page-card {
    width: 100% !important;
    max-width: 460px !important;
  }
}

@media (max-width: 775px) {
  .app-wrapper {
    min-height: calc(100vh - 183px);
  }
}
</style>
