<template>
  <v-footer>
    <!-- Contact information -->
    <div class="d-flex">
      <div class="footer-section">
        <h3 class="footer-text secondary-font" style="font-weight: bold; text-transform: uppercase;">
          Contact PCA
        </h3>
        <div class="footer-text secondary-font">PCA National Headquarters</div>
        <div class="footer-text secondary-font">P.O. Box 6400</div>
        <div class="footer-text secondary-font">Columbia, MD 21045</div>
      </div>
  
      <!-- Physical address -->
      <div class="footer-section ml-6   " style="margin-top: -12px">
        <a
          :href="'tel:' + phoneNumber"
          class="footer-text secondary-font"
          style="font-weight: bold"
        >
          +1 410 381 0911
        </a>
        <div class="footer-text secondary-font">Physical Address:</div>
        <div class="footer-text secondary-font">9689 Gerwig Lane, Unit 4C/D</div>
        <div class="footer-text secondary-font">Columbia, MD 21046</div>
      </div>
    </div>

    <!-- Social links and copyright -->
    <div class="d-flex flex-column justify-center align-center footer-section">
      <!-- Social media icons -->
      <div>
        <v-btn
          v-for="(link, index) in socialLinks"
          :href="link.url"
          :key="index"
          icon
          class="text-white"
          target="_blank"
          variant="text"
        >
          <v-icon>{{ link.icon }}</v-icon>
        </v-btn>
      </div>
      <!-- Copywrite text -->
      <div class="footer-text secondary-font">
        &copy; {{ currentYear }} The Porsche Club of America Inc. All rights
        reserved
      </div>
    </div>

    <!-- PCA logo -->
    <img
      src="../../assets/img/pca_white_headerlogo.png"
      alt="PCA Logo"
      class="footer-pca-logo"
    />
  </v-footer>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';

export default defineComponent({
  name: 'Footer',

  setup() {
    const phoneNumber = ref('14103810911');

    const socialLinks = ref([
      {
        icon: 'mdi-youtube',
        url: 'https://youtube.com/porscheclubofamerica',
      },
      {
        icon: 'mdi-instagram',
        url: 'https://instagram.com/porscheclubofamerica',
      },
      {
        icon: 'mdi-facebook',
        url: 'https://facebook.com/porscheclubofamerica',
      },
    ]);

    const currentYear = computed(() => new Date().getFullYear());

    return {
      phoneNumber,
      socialLinks,
      currentYear,
    };
  },
});
</script>

<style scoped lang="scss">
.v-footer {
  background-color: $pca-blue;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.footer-text {
  color: white;
  font-size: 8px;
  font-weight: 300;
}

.footer-pca-logo {
  width: auto;
  height: 35px;
}

.v-btn--icon {
  width: 36px;
  height: 36px;
}

@media (max-width: 775px) {
  .v-footer {
    flex-direction: column;
  }

  .footer-section {
    margin-bottom: 12px
  }
}
</style>
