import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "app-wrapper" }
const _hoisted_3 = {
  class: "secondary-font",
  style: {"font-size":"14px","font-weight":"600"}
}
const _hoisted_4 = {
  key: 0,
  class: "secondary-font",
  style: {"font-size":"14px","font-weight":"600"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingCarWheelSpinner = _resolveComponent("LoadingCarWheelSpinner")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.initDataStore.publicProps.appLoaded)
      ? (_openBlock(), _createBlock(_component_LoadingCarWheelSpinner, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.mountApp)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_router_view),
            _createVNode(_component_v_snackbar, {
              modelValue: _ctx.notification.show,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.notification.show) = $event)),
              color: _ctx.notification.color,
              timeout: _ctx.notification.timeout
            }, {
              actions: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  icon: "mdi-close",
                  variant: "text",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.notification.show = false))
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.notification.message), 1),
                (_ctx.notification.caption)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.notification.caption), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue", "color", "timeout"])
          ]),
          _createVNode(_component_Footer)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}