// Types
import { RouteRecordRaw } from 'vue-router';

// Local Types
export enum RoutePath {
  CATCH_ALL = '/:catchAll(.*)*',
  HOME = '/home',
  REGISTER_PRIMARY_ENTRANT = '/register-primary-entrant',
  REGISTER_CO_ENTRANT = '/register-co-entrant',
  REGISTER_GUEST = '/register-guest',
  REGISTER_PCA_JUNIOR = '/register-pca-junior',
  REGISTER_CHILD = '/register-child',
  CHECKOUT = '/checkout',
  PAYMENT_CONFIRMATION = '/payment-confirmation',
  LOGIN = '/',
}

const routes: RouteRecordRaw[] = [
  {
    children: [],
    component: () => import('@/pages/HomePage.vue'),
    path: RoutePath.HOME,
  },
  {
    children: [],
    component: () => import('@/pages/Login.vue'),
    path: RoutePath.LOGIN,
  },
  {
    children: [],
    component: () => import('@/pages/RegisterPrimaryEntrant.vue'),
    path: RoutePath.REGISTER_PRIMARY_ENTRANT,
  },
  {
    children: [],
    component: () => import('@/pages/RegisterCoEntrant.vue'),
    path: RoutePath.REGISTER_CO_ENTRANT,
  },
  {
    children: [],
    component: () => import('@/pages/RegisterGuest.vue'),
    path: RoutePath.REGISTER_GUEST,
  },
  {
    children: [],
    component: () => import('@/pages/RegisterPCAJunior.vue'),
    path: RoutePath.REGISTER_PCA_JUNIOR,
  },
  {
    children: [],
    component: () => import('@/pages/RegisterChild.vue'),
    path: RoutePath.REGISTER_CHILD,
  },
  {
    children: [],
    component: () => import('@/pages/Checkout.vue'),
    path: RoutePath.CHECKOUT,
  },
  {
    children: [],
    component: () => import('@/pages/PaymentConfirmation.vue'),
    path: RoutePath.PAYMENT_CONFIRMATION,
  },
  {
    component: () => import('@/pages/PageNotFound.vue'),
    path: RoutePath.CATCH_ALL,
  },
];

export default routes;
