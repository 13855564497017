// Types
import {
  EntrantTypesResponse,
  EventDetailsResponse,
  GendersResponse,
  PCAAgeRangesResponse,
  RegionsResponse,
} from '@/types/apiResponses';
import { 
  EntrantTypeOptions, 
  EventDetails, 
  GenderOptions, 
  PCAAgeRangeOptions, 
  RegionOptions 
} from '@/types/formOptions';
import { FormType } from '@/types/user';

// Utils
import { defineStore } from 'pinia';

// Local types
type AppDataStoreState = {
  pcaAgeRangeOptions: PCAAgeRangeOptions;
  entrantTypeOptions: EntrantTypeOptions;
  eventDetails: EventDetails;
  genderOptions: GenderOptions;
  regionOptions: RegionOptions;
};

export const useAppDataStore = defineStore('appDataStore', {
  state: (): AppDataStoreState => ({
    pcaAgeRangeOptions: [] as unknown as PCAAgeRangeOptions,
    entrantTypeOptions: [] as unknown as EntrantTypeOptions,
    eventDetails: {} as EventDetails,
    genderOptions: [] as unknown as GenderOptions,
    regionOptions: [] as RegionOptions,
  }),

  getters: {
    /**
     * @name getPCAAgeRangeOptions
     * @description Returns the age ranges options
     */
    getPCAAgeRangeOptions(): AppDataStoreState['pcaAgeRangeOptions'] {
      return this.pcaAgeRangeOptions;
    },

    /**
     * @name getCoEntrantTypeOptions
     * @description Returns the co-entrant types options
     */
    getCoEntrantTypeOptions(): AppDataStoreState['entrantTypeOptions'] {
      return this.entrantTypeOptions;
    },

    /**
     * @name getGenderOptions
     * @description Returns the genders options
     */
    getGenderOptions(): AppDataStoreState['genderOptions'] {
      return this.genderOptions;
    },

    /**
     * @name getRegionOptions
     * @description Returns the regions options
     */
    getRegionOptions(): AppDataStoreState['regionOptions'] {
      return this.regionOptions;
    },

    /**
     * @name getEventDetails
     * @description Returns the event details
     */
    getEventDetails(): AppDataStoreState['eventDetails'] {
      return this.eventDetails;
    },

    /**
     * @name getRegistrationPrices
     * @description Returns the prices for different registration types
     */
    getRegistrationPrices(): {
      adult: number;
      base: number;
      cafp: number;
      coEntrant: number;
      jpp: number;
      lateFee: number;
      preteen: number;
      teen: number;
    } {
      return {
        adult: Number(this.eventDetails.ADULT_COST),
        base: Number(this.eventDetails.BASE_COST),
        cafp: Number(this.eventDetails.TEEN_COST),
        coEntrant: Number(this.eventDetails.CO_ENTRANT_COST),
        jpp: Number(this.eventDetails.JPP_COST),
        lateFee: Number(this.eventDetails.LATE_FEE),
        preteen: Number(this.eventDetails.PRETEEN_COST),
        teen: Number(this.eventDetails.TEEN_COST),
      };
    },
  },

  actions: {
    /**
     * @name setOptionsData
     * @description Takes data form API responses and reformats it to fit the options data structure we need on the FE
     */
    setOptionsData(data: {
      pcaAgeRanges: PCAAgeRangesResponse;
      entrantTypes: EntrantTypesResponse;
      eventDetails: EventDetailsResponse;
      genders: GendersResponse;
      regions: RegionsResponse;
    }): void {
      this.pcaAgeRangeOptions = data.pcaAgeRanges.map((pcaAgeRange: PCAAgeRangesResponse[number]) => {
        return {
          title:
            pcaAgeRange.AGE_RANGE_ID === 1
              ? 'Adult'
              : pcaAgeRange.AGE_RANGE_ID === 2
              ? 'College Age (CAFP 18-25)'
              : pcaAgeRange.AGE_RANGE_ID === 3
              ? 'Junior (JPP 13-17)'
              : pcaAgeRange.AGE_RANGE_ID === 4
              ? 'Teen (13-15)'
              : 'Preteen (11-12)',
          value: pcaAgeRange.AGE_RANGE_ID,
        };
      }) as PCAAgeRangeOptions;
      this.entrantTypeOptions = data.entrantTypes.map((entrantType: EntrantTypesResponse[number]) => {
        return {
          title:
            entrantType.ENTRANT_TYPE_ID === 1
              ? 'Entrant'
              : entrantType.ENTRANT_TYPE_ID === 2
              ? 'Co-Entrant'
              : 'Registrant',
          value: entrantType.ENTRANT_TYPE_ID,
        };
      }) as EntrantTypeOptions;
      this.genderOptions = data.genders.map((gender: GendersResponse[number]) => {
        return {
          title:
            gender.GENDER_ID === 1
              ? 'Male'
              : gender.GENDER_ID === 2
              ? 'Female'
              : 'Prefer not to say',
          value: gender.GENDER_ID,
        };
      }) as GenderOptions;
      this.eventDetails = data.eventDetails;
      this.regionOptions = data.regions.map((region: RegionsResponse[number]) => {
        return {
          title: `${region.REGIONNAME} (${region.REGIONCODE})`,
          value: region.REGIONID,
        };
      });
    },
  },
});
